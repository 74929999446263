<template>
  <AView
    :value="value"
    :actions="actions"
    :hasFilters="!!filter.search"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
  >
    <template v-slot:content="{}">
      <v-row>
        <v-col cols="12">
          <FormSection
            right
            :label="'Glossary'"
            :icon="value.view.display.icon"
            :actions="actions"
            :editable="!!actions.length"
            @action="(action) => action.handler({ from: value })"
          ></FormSection>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-slide-group
            show-arrows
            v-model="filter.startLetter"
            class="mb-4"
            center-active
          >
            <v-slide-item
              v-slot="{ active, toggle }"
              v-for="letter in letters"
              :key="letter.letter"
            >
              <v-badge
                color="primary"
                :value="letter.count"
                :content="letter.count"
                inline
              >
                <v-btn
                  class="mx-1 transparent"
                  :input-value="active"
                  active-class="primary white--text"
                  depressed
                  rounded
                  @click="toggle"
                >
                  {{ letter.letter }}
                </v-btn>
              </v-badge>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pa-0 ma-0">
          <v-virtual-scroll
            class="pa-0 ma-0"
            :bench="5"
            :items="terms"
            height="600"
            item-height="260"
          >
            <template v-slot:default="{ item }">
              <GlossaryTermItem
                :style="{
                  height: '240px',
                }"
                class="ma-2"
                :key="item.id"
                :value="item"
                @click="onSelect"
                @mouseover="onHover"
                @mouseleave="() => {}"
                :active="activeTermId === item.id"
              ></GlossaryTermItem>
            </template>
          </v-virtual-scroll>
        </v-col>
      </v-row>

      <Pagination
        :value="pagination"
        :total="terms.length"
        @onLoadMore="
          $store.dispatch('GlossaryStore/GetTermsList', {
            next: true,
          })
        "
      ></Pagination>
    </template>

    <template v-slot:actions="{}">
      <GlossaryFilters v-model="filter"> </GlossaryFilters>
    </template>
  </AView>
</template>
    
    
    
<script>
import { mapState } from "vuex";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import FormSection from "../../../../../../components/wad/atoms/common/FormSection.vue";
import Pagination from "../../../../../../components/wad/atoms/common/Pagination.vue";
import GlossaryFilters from "../../../../../../components/wad/molecules/knowledgeBase/glossary/GlossaryFilters.vue";
import GlossaryTermItem from "../../../../../../components/wad/organisms/glossary/GlossaryTermItem.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { GLOSSARY_PERMISSIONS } from "../../constants/permissions.gh";
import { GLOSSARY_BUTTONS } from "../../constants/actions.gh";

export default {
  name: "Glossary",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    FormSection,
    GlossaryTermItem,
    GlossaryFilters,
    Pagination,
  },
  computed: {
    ...mapState("GlossaryStore", [
      "terms",
      "letters",
      "activeTermId",
      "pagination",
    ]),
    actions: function () {
      return PermissionsHelper.getActions(
        GLOSSARY_BUTTONS.CREATE_NEW,
        this.value.permissions
      );
    },
  },
  async created() {
    await this.$store.dispatch("GlossaryStore/GetLetters");

    this.$store.dispatch("GlossaryStore/GetTermsList", {
      filter: {
        ...this.filter,
        startLetter: this.letters[this.filter.startLetter].letter,
      },
      reset: true,
    });
  },
  data() {
    return {
      GLOSSARY_PERMISSIONS,
      filter: {
        startLetter: 0,
      },
      data: {
        services: [],
      },
    };
  },
  methods: {
    onHover(value) {
      this.$store.commit("GlossaryStore/setActiveId", value.id);
    },
    onCreate() {
      this.$store.dispatch("GlossaryStore/openTermCreation", {
        from: this.value,
      });
    },
    onSelect(term) {
      this.$store.dispatch("GlossaryStore/SetActiveTerm", {
        from: this.value,
        term,
      });
    },
  },
  watch: {
    filter: {
      handler(newVal, oldVal) {
        if (oldVal.search && !newVal.search) this.filter.startLetter = 0;

        this.$store.dispatch("GlossaryStore/GetTermsList", {
          filter: {
            ...newVal,
            startLetter: this.letters[newVal.startLetter]
              ? this.letters[newVal.startLetter].letter
              : undefined,
          },
          reset: true,
        });
      },
      deep: true,
    },
  },
};
</script>
    
    
    
    <style scoped lang="scss" >
.users-list {
  height: 100%;
  width: 100%;
  // ::v-deep button {
  //   background: white !important;
  // }
}

.v-slide-group__wrapper {
  padding-top: 20px;
}
</style>